import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  dialogClose: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  buttonArchive: {
    color: 'red',
  },
  buttonCancel: {
    color: 'black',
  },
  titleText: {
    fontSize: '18px',
    fontWeight: '600',
    color: 'black',
  },
  contentText: {
    color: 'black',
    fontSize: '16px',
    fontWeight: '400',
  },
  contentTextBold: {
    fontSize: '16px',
    fontWeight: '600',
    color: 'black',
  },
}));
