import React, { useState, useContext, useEffect, useCallback } from 'react';
import _ from 'underscore';
import { useAuth } from 'auth/AuthProvider';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { collection, getDocs, getFirestore, query } from 'firebase/firestore';

import { FirebaseContext } from '../../utils/firebase';
import { snapToArray } from '../../utils';
import useStyles from './styles';

const WelcomeView = () => {
  const initialState = {
    values: {
      tenantId: '',
      tenant: null,
    },
    isValid: false,
    isLoading: false,
    submitError: null,
  };

  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const { user, updateUserTenant } = useAuth();
  const [tenants, setTenants] = useState([]);
  const { firebaseApp } = useContext(FirebaseContext);
  const database = getFirestore(firebaseApp);

  const setLoading = loading => {
    setState({ ...state, isLoading: loading });
  };

  const getTenants = useCallback(async () => {
    const tenantQuery = query(collection(database, 'tenants'));
    const snapshot = await getDocs(tenantQuery);
    return snapToArray(snapshot);
  }, [database]);

  useEffect(() => {
    getTenants()
      .then(tenants => setTenants(tenants))
      .catch(() => {
        setTenants([]);
      });
  }, [getTenants]);

  const findTenant = tenantId => {
    return tenants
      .filter(tenant => tenant.id.toString() === tenantId.toString())
      .reduce((_accumulator, currentValue) => currentValue, {});
  };

  const handleSignIn = async () => {
    try {
      const { tenant } = state.values;
      setLoading(true);
      await updateUserTenant(user.uid, tenant);
    } catch (error) {
      setState(...state, {
        isLoading: false,
        submitError: error,
      });
    }
  };

  const handleChange = () => event => {
    const tenantId = event.target.value;
    const tenant = findTenant(tenantId);
    const newState = { ...state };

    newState.submitError = null;
    newState.values.tenantId = tenantId;
    newState.values.tenant = {
      id: tenant.id,
      name: tenant.name,
    };
    newState.isValid = !_.isEmpty(newState.values.tenant);

    setState(newState);
  };

  const { values, isValid, submitError, isLoading } = state;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.leftWrapper} item lg={4}>
          <div className={classes.leftSection} />
        </Grid>
        <Grid className={classes.content} item lg={8} xs={12}>
          <div className={classes.form}>
            <div className={classes.logo} />
            <Typography className={classes.subtitle} variant="body1">
              Welcome to
            </Typography>
            <Typography component="div" className={classes.title} variant="h4">
              <Box fontWeight="600" display="inline">
                ProductDock{' '}
              </Box>
              Oversight
            </Typography>
            <Typography className={classes.info} variant="body1">
              Please select your office location
            </Typography>
            <div className={classes.fields}>
              <RadioGroup
                className={classes.radioGroup}
                value={values.tenantId}
                onChange={handleChange()}
              >
                {tenants.map(tenant => (
                  <FormControlLabel
                    key={tenant.id}
                    className={classes.radioButton}
                    value={tenant.id}
                    control={<Radio color="primary" />}
                    label={tenant.name}
                  />
                ))}
              </RadioGroup>
            </div>
            {submitError && (
              <Typography className={classes.submitError} variant="body2">
                {submitError}
              </Typography>
            )}
            {isLoading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Button
                className={classes.signUpButton}
                color="primary"
                disabled={!isValid}
                onClick={handleSignIn}
                size="large"
                variant="contained"
              >
                SIGN IN
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default WelcomeView;
