import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '0 0 0.25rem 0.25rem',
  },
  portletRoot: { borderTop: 'none', marginTop: '20%' },
  portletContent: {
    padding: '0.5rem 1.5rem',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
  },
  disabledForm: {
    textAlign: 'center',
    padding: '11rem 1.5rem',
  },
  toolbarButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    fontSize: '1.125rem',
  },
  icon: {
    height: '2rem',
    width: '2.5rem',
  },
  budgetCosts: {
    marginTop: theme.spacing(1),
  },
  enrolmentFee: {
    fontSize: '0.938rem',
  },
}));
