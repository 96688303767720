import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '63px',
    flexShrink: 0,
    objectFit: 'contain',
  },
  logoLink: {
    fontSize: 0,
  },
  logoImage: {
    cursor: 'pointer',
    width: '33px',
    marginLeft: '-81px',
  },
  tenant: {
    display: 'flex',
    flexDirection: 'column',
  },
  companyName: {
    fontSize: '21px',
    marginLeft: '-37px',
    fontWeight: '600',
  },
  tenantName: {
    marginLeft: theme.spacing(-4.5),
    color: '#686868',
  },
  logoDivider: {
    marginBottom: theme.spacing(2),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    padding: '25px 0',
  },
  avatar: {
    width: '70px',
    height: '70px',
  },
  nameText: {
    marginTop: theme.spacing(2),
  },
  bioText: {},
  profileDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  listSubheader: {
    color: theme.palette.text.secondary,
    lineHeight: theme.spacing(0.2),
    textIndent: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
  listItem: {
    cursor: 'pointer',
    transition: `background-color ${theme.transitions.main}`,
    '&:hover': {
      backgroundColor: '#F7F7F9',
      '& $listItemText': {
        color: theme.palette.text.secondary,
      },
      '& $listItemIcon': {
        color: theme.palette.text.secondary,
      },
    },
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  activeListItem: {
    backgroundColor: '#F5FBFF',
    '& $listItemText': {
      color: theme.palette.primary.main,
    },
    '& $listItemIcon': {
      color: theme.palette.primary.main,
    },
  },
  listItemIcon: {
    marginRight: 0,
  },
  listItemText: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  listDivider: {
    marginBottom: theme.spacing(1),
  },
  listItemMenu: {
    overflow: 'hidden',
    paddingBottom: 0,
  },
  listItemTitle: {
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    transition: `background-color ${theme.transitions.main}`,
    '&:hover': {
      backgroundColor: '#F7F7F9',
      '& $listItemText': {
        color: theme.palette.text.secondary,
      },
      '& $listMenuItemIcon': {
        color: theme.palette.text.secondary,
      },
    },
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },

  nestedListItemWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1),
  },

  nestedListItem: {
    textIndent: theme.spacing(4),
    cursor: 'pointer',
    transition: `background-color ${theme.transitions.main}`,
    '&:hover': {
      backgroundColor: '#F7F7F9',
      '& $listItemText': {
        color: theme.palette.text.secondary,
      },
    },
  },
  listItemIconArrow: {
    marginRight: theme.spacing(1),
  },
  listMenuItemIcon: {
    marginLeft: theme.spacing(2),
  },

  badgeDot: {
    right: theme.spacing(2),
  },

  badgeNumber: {
    left: theme.spacing(2),
    textIndent: theme.spacing(0),
    '& .MuiBadge-colorPrimary': {
      color: theme.palette.primary.main,
      backgroundColor: '#C8EAFF',
    },
  },
}));
