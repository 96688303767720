import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '1.125rem 1.5rem',
    borderRadius: '0.25rem',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '0.75rem',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    color: '#000000',
    fontSize: '1.125rem',
    fontWeight: 500,
  },
  days: {
    textAlign: 'end',
  },
  months: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '1rem',
  },
  month: {
    flexDirection: 'column',
    paddingRight: '0.125rem',
    paddingLeft: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
  currentMonth: {
    backgroundColor: '#F7F7F9',
    cursor: 'pointer',
  },
  currentMonthWidth: {
    width: '5rem',
  },
  separator: {
    borderLeft: `1px solid ${theme.palette.border}`,
    marginRight: '0.563rem',
    marginLeft: '0.563rem',
    '@media (max-width:480px)': {
      marginRight: '0.25rem',
      marginLeft: '0.25rem',
    },
  },
  monthBillableDays: {
    '& span': {
      fontSize: '1.125rem',
      color: '#000000',
      fontWeight: 500,
    },
  },
  monthName: {
    '& span': {
      fontSize: '0.875rem',
    },
  },
  monthNotLogged: {
    '& span': {
      color: 'red',
      cursor: 'pointer',
    },
  },
  notLogged: {
    backgroundColor: '#FEF6F4',
    textAlign: 'center',
  },
  noBillableDays: {
    textAlign: 'center',
  },
  progress: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  titleProgressbar: {
    width: theme.spacing(8),
  },
}));
