import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4),
    '& .rbc-calendar': {
      boxShadow: '0px 5px 16px 0px rgba(0,0,0,0.15);',
    },
    '& .rbc-toolbar': {
      padding: '15px 25px',
    },
    '& .rbc-header': {
      borderLeft: '0px',
      padding: '12px 6px',
      fontWeight: 500,
      fontSize: 14,
    },
    '& .rbc-row .rbc-month-header': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .rbc-label': {
      padding: '8px 6px',
    },
    '& .rbc-header.rbc-today, & .rbc-month-view .rbc-day-bg.rbc-today': {
      borderBottom: `2px solid ${theme.palette.danger.main}!important`,
    },
    '& .rbc-month-view, & .rbc-time-view, & .rbc-agenda-view': {
      padding: 24,
      [theme.breakpoints.down('sm')]: {
        padding: 16,
      },
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .rbc-agenda-view table': {
      border: `1px solid ${theme.palette.secondary.main}`,
      '& thead > tr > th': {
        borderBottom: `0px solid ${theme.palette.secondary.main}`,
      },
      '& tbody > tr > td': {
        padding: '12px 6px',
        '& + td': {
          borderLeft: `1px solid ${theme.palette.secondary.main}`,
        },
      },
      '& tbody': {
        color: '#fff',
      },
    },
    '& .rbc-time-view': {
      '& .rbc-time-header': {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
      '& .rbc-time-content': {
        flex: '0 1 auto',
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
    '& .rbc-month-view': {
      border: 'none !important',
      '& > .rbc-row': {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      },
      '& .rbc-month-row': {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderWidth: '0 1px 1px 1px!important',
        minHeight: 95,
      },
      '& .rbc-header': {
        borderBottom: `0px solid ${theme.palette.secondary.main}`,
      },
      '& .rbc-day-bg + .rbc-day-bg': {
        borderLeft: `1px solid ${theme.palette.secondary.main}`,
      },
    },
    '& .rbc-day-slot .rbc-time-slot': {
      borderTop: `1px solid ${theme.palette.secondary.main}`,
      opacity: 0.5,
    },
    '& .rbc-time-header > .rbc-row > * + *': {
      borderLeft: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .rbc-time-content > * + * > *': {
      borderLeft: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .rbc-day-bg + .rbc-day-bg': {
      borderLeft: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .rbc-time-header > .rbc-row:first-child': {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .rbc-timeslot-group': {
      minHeight: 64,
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .rbc-date-cell': {
      padding: '6px',
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 400,
      opacity: 0.5,
      '& > a': {
        color: 'inherit',
      },
    },
    '& .rbc-btn-group': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .rbc-toolbar-label': {
      ...theme.typography.body2,
      fontWeight: 600,
    },
    '& .rbc-today': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .rbc-event': {
      fontSize: 12,
      borderRadius: 4,
      padding: '2px 15px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[0],
      transitionProperty: 'box-shadow',
      transitionDuration: theme.transitions.duration.short,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      position: 'relative',
      '&:hover': {
        boxShadow: theme.shadows[2],
      },
    },
    '& .rbc-row-segment': {
      padding: '0 4px 4px 4px',
    },
    '& .rbc-off-range-bg': {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(0,0,0,0.03)' : 'rgba(0,0,0,0.16)',
    },
    '& .rbc-show-more': {
      color: theme.palette.secondary.main,
      background: 'transparent',
    },
    '& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event': {
      position: 'static',
    },
    '& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child': {
      left: 0,
      top: 0,
      bottom: 0,
      height: 'auto',
    },
    '& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child': {
      right: 0,
      top: 0,
      bottom: 0,
      height: 'auto',
    },
  },
  loading: {
    opacity: 0.5,
  },
}));
