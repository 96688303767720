import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '0 0 0.25rem 0.25rem',
  },
  portletRoot: { borderTop: 'none', marginTop: '20%' },
  endDate: {
    display: 'block',
  },
  portletContent: {
    padding: '0.5rem 1.5rem',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
  },
  toolbarButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    fontSize: '1rem',
  },
  description: {
    marginBottom: theme.spacing(3),
  },
}));
