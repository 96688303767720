import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  title: { fontWeight: 600 },

  forms: {
    '@media (max-width:1279px)': {
      order: 1,
    },
  },

  selectHeader: {
    padding: '1.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.main,
    borderRadius: '0.25rem 0.25rem 0px 0px',
  },

  labelColor: {
    color: '#fff',
    '&.Mui-focused': {
      color: '#fff',
    },
  },

  titleColor: {
    color: '#fff',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: '1rem',
  },

  select: {
    color: '#fff',
    '&.Mui-hover': {
      color: '#fff',
    },
    '&:before': {
      borderColor: '#fff',
    },
    '&:after': {
      borderColor: '#fff',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: '#fff',
    },
  },

  icon: {
    fill: '#fff',
  },
}));
