import { ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles, styled } from '@material-ui/styles';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    '&.Mui-selected': {
      background: '#EEEEEE',
      color: '#000000',
    },
  },
  '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
    marginLeft: 0,
  },
}));

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '1.75rem 1.5rem 0.875rem 1.5rem',
    borderRadius: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0',
    gap: '1.5rem',
    flex: 'none',
    order: '0',
    flexGrow: '0',
  },
  content: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '1.063rem',
    marginBottom: '1.063rem',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toggleButton: {
    fontSize: '0.875rem',
    color: '#000000',
    height: '2rem',
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  title: {
    color: '#000000',
    fontSize: '1.125rem',
    fontWeight: 500,
  },
  bottomLabel: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#4c4c4c',
  },
  value: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#000000',
  },
  counterTotal: {
    fontSize: '1rem',
    fontWeight: 300,
  },
  counterDisabled: {
    color: '#CCCCCC',
  },
  counterUsed: {
    fontWeight: 500,
  },
  counterCircle: {
    borderRadius: '50%',
    backgroundColor: '#F7F7F9',
    cursor: 'pointer',
  },
  progressPrimary: {
    color: theme.palette.primary.main,
  },
  progressSecondary: {
    color: '#CCCCCC',
  },
  progressTransparent: {
    color: 'transparent',
  },
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  difference: {
    alignItems: 'center',
    color: theme.palette.primary.main,
    display: 'inline-flex',
    fontWeight: 600,
  },
  caption: {
    marginLeft: theme.spacing(1),
  },
}));
