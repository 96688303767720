export default () => ({
  tableCell: {
    minWidth: props => props.width,
    maxWidth: 300,
    fontWeight: 400,
  },
  oneLine: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});
